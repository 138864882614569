
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: Number
  },
  data() {
    return {
      report: {},
      mainColor: "primary"
    };
  },
  methods: {
    initData(row) {
      const exam_id = row.exam_id;
      ApiService.post("Exam/MyReportCard", { exam_id } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            if (data.data) {
              this.report = data.data;
              this.mainColor = ((this.report as any).is_pass == '1')?"danger":"warning";
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }
  }
});
