
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import ExamReport from "@/views/exam/ExamReport.vue";
import store from "@/store";
import KTFacePhoto from "@/views/face/FacePhoto.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "modal-card",
  props: {
    image: String,
    modalId: String
  },
  components: {
    ExamReport,
    KTFacePhoto
  },
  data() {
    return {
      path: "",
      row: {},
      row_status: {},
      face_photo: ""
    };
  },
  methods: {
    getExamAnswerDetail() {
      const data = {
        exam_id: (this.row as any).exam_id
      };
      ApiService.post("Exam/ExamAnswerDetail", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.row = data.data.exam;
            this.row_status = data.data.answer_status;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleExamReport() {
      (this.$refs.report as any).initData(this.row);
    },
    handleExamPreview() {
      this.$router.push({
        path: "/exam/start",
        query: {
          exam_id: (this.row as any).exam_id,
          is_preview: "1",
          path: this.path
        }
      });
    },
    handleExamStart() {
      let exam_id = (this.row as any).exam_id;
      const data = {
        exam_id: exam_id
      };
      ApiService.post("Exam/ExamReady", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.$router.push({
              path: "/exam/start",
              query: { exam_id: (this.row as any).exam_id, path: this.path }
            });
          } else {
            //Toast.warning(data.message);
            if (data.code == "99") {
              const modal = new Modal(
                document.getElementById("kt_modal_face_photo")
              );
              (this.$refs["KTFacePhoto"] as any).initData();
              modal.show();
            }
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    /* 活跃度检测 */
    handleDetection() {
      let data = {
        exam_id: (this.row as any).exam_id,
        process_type: "2", //行为类型 1学时行为类型的活跃度检测 2考试行为类型的活跃度检测
        face_photo: this.face_photo
      };
      ApiService.post("Exam/ClassDetection", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.success(data.message);
            this.handleExamStart();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({ path: this.path });
      });
      Bus.$on("setFacePhoto", (data) => {
        this.face_photo = data;
        this.handleDetection(); //活跃度检测
      });
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("准备", ["考试"]);
    setCurrentPageButton("back", true);
    this.initBus();
    const params = store.getters.currentSecondaryParams;
    this.path = params.path;
    this.row = JSON.parse(params.row);
    this.getExamAnswerDetail();
  }
});
